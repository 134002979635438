import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

const NotFound = () => {
  const [paddleA, setPaddleA] = useState(2);
  const [paddleB, setPaddleB] = useState(2);
  const [ballX, setBallX] = useState(5);
  const [ballY, setBallY] = useState(5);
  const [ballDX, setBallDX] = useState(1);
  const [ballDY, setBallDY] = useState(1);
  const [score, setScore] = useState({ a: 0, b: 0 });

  const movePaddle = useCallback(
    (e) => {
      if (e.key === "w" && paddleA > 0) setPaddleA((p) => p - 1);
      if (e.key === "s" && paddleA < 7) setPaddleA((p) => p + 1);
      if (e.key === "ArrowUp" && paddleB > 0) setPaddleB((p) => p - 1);
      if (e.key === "ArrowDown" && paddleB < 7) setPaddleB((p) => p + 1);
    },
    [paddleA, paddleB]
  );

  useEffect(() => {
    window.addEventListener("keydown", movePaddle);
    return () => window.removeEventListener("keydown", movePaddle);
  }, [movePaddle]);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      setBallX((x) => x + ballDX);
      setBallY((y) => y + ballDY);

      if (ballY <= 0 || ballY >= 9) setBallDY((dy) => -dy);

      if (ballX <= 1 && ballY >= paddleA && ballY <= paddleA + 2) {
        setBallDX(1);
      } else if (ballX >= 9 && ballY >= paddleB && ballY <= paddleB + 2) {
        setBallDX(-1);
      }

      if (ballX < 0) {
        setScore((s) => ({ ...s, b: s.b + 1 }));
        setBallX(5);
        setBallY(5);
      } else if (ballX > 10) {
        setScore((s) => ({ ...s, a: s.a + 1 }));
        setBallX(5);
        setBallY(5);
      }
    }, 200);

    return () => clearInterval(gameLoop);
  }, [ballDX, ballDY, paddleA, paddleB]);

  const renderGame = () => {
    let game = Array(10)
      .fill()
      .map(() => Array(11).fill(" "));

    // Draw paddles
    for (let i = 0; i < 3; i++) {
      if (paddleA + i < 10) game[paddleA + i][0] = "|";
      if (paddleB + i < 10) game[paddleB + i][10] = "|";
    }

    // Draw ball
    if (ballY >= 0 && ballY < 10 && ballX >= 0 && ballX < 11) {
      game[ballY][ballX] = "O";
    }

    return game.map((row, i) => (
      <div key={i} style={{ fontFamily: "monospace", whiteSpace: "pre" }}>
        {row.join("")}
      </div>
    ));
  };

  return (
    <Layout>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>404 - page not found</h1>
        <p>"A dead end does not mean the path had no merit"</p>
        {/* TODO: Make the pong game look better */}
        {/* <p>play some pong while you're here</p>
        <p>player A: W/S | player B: ↑/↓</p>
        <div>{renderGame()}</div>
        <p>
          score: player A {score.a} - {score.b} player B
        </p> */}
        <div>
          <img
            src="https://images.fineartamerica.com/images-medium-large-5/modern-abstract-expressive-lost-ginette-callaway.jpg"
            alt="404"
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Link style={{ position: "relative", color: "white" }} to="/">
            home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
