import React, { useState, useEffect } from "react";
import ContentList from "../../components/ContentList";
import Layout from "../../components/Layout";

function Home({ children }) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const contentList = [
      { id: "school", text: "Student at Amador Valley High School" },
      {
        id: "gatekeep",
        text: "Head of Engineering at ",
        link: { text: "Gatekeep Labs", url: "https://gatekeep.ai" },
      },
      {
        id: "gatekeep-desc",
        text: "Creating an AI video generator",
        indent: true,
      },
      {
        id: "gatekeep-desc",
        text: "Developing a next-gen edtech platform",
        indent: true,
      },
      {
        id: "kairos",
        text: "Founder at  ",
        link: { text: "Kairos", url: "https://kairoslabs.xyz" },
      },
      {
        id: "kairos-desc",
        text: "Understanding how the mind works, and building tools to augment it",
        indent: true,
      },
      {
        id: "kairos-desc",
        text: "Product 1 got acquired. On to the next.",
        indent: true,
      },

      // {
      //   id: "stanford",
      //   text: "Research Intern at Stanford SALT/CMU Neubig Lab",
      // },
      // {
      //   id: "stanford-desc",
      //   text: "Vision transformers for image transcreation and style transfer",
      //   indent: true,
      // },
      { id: "medicine", text: "Researcher at Stanford Medicine SCI3 Lab" },
      {
        id: "medicine-desc",
        text: "Lead developer on the ",
        link: {
          text: "Stanford BiRAT Platform",
          url: "https://birat.stanford.edu",
        },
        indent: true,
      },
      {
        id: "medicine-desc",
        text: "Working on 3D segmentation models for early-stage tumor detection",
        indent: true,
      },
      {
        id: "opennlp",
        text: "CFO at ",
        link: { text: "OpenNLP", url: "https://opennlplabs.org" },
      },
      {
        id: "opennlp-desc",
        text: "Building an NLP Lab for Safe AI",
        indent: true,
      },
      {
        id: "opennlp-desc",
        text: "Working in partnership with Stanford, CMU, OpenAI, and others",
        indent: true,
      },
      {
        id: "thinking-about",
        text: "Things I think about:",
        indent: false,
      },
      {
        id: "thinking-about-desc",
        text: "startups",
        indent: true,
      },
      {
        id: "thinking-about-desc",
        text: "autonomy",
        indent: true,
      },
      {
        id: "thinking-about-desc",
        text: "alignment",
        indent: true,
      },
      {
        id: "thinking-about-desc",
        text: "consciousness",
        indent: true,
      },
      {
        id: "thinking-about-desc",
        text: "success principles (or the lack thereof)",
        indent: true,
      },
      {
        id: "thinking-about-desc",
        text: "locking in",
        indent: true,
      },
    ];
    setContent(contentList);
  }, []);

  return (
    <Layout>
      <ContentList speed={10} items={content} />
    </Layout>
  );
}

export default Home;
