import React, { useState, useEffect } from "react";

function ContentList({ items, speed = 20, typewriterFunction }) {
  const [visibleItems, setVisibleItems] = useState([]);
  const [currentText, setCurrentText] = useState("");
  const [currentLinkText, setCurrentLinkText] = useState("");
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isTypingLink, setIsTypingLink] = useState(false);

  useEffect(() => {
    const customTypewriter = () => {
      setVisibleItems(
        items.map((item) => ({
          ...item,
          text: item.text,
          linkText: item.link?.text || "",
        }))
      );
    };

    if (typewriterFunction === "autoload") {
      customTypewriter();
    } else {
      if (currentItemIndex >= items.length) return;

      const currentItem = items[currentItemIndex];
      const text = currentItem.text;
      const linkText = currentItem.link?.text || "";

      const typeNextChar = () => {
        if (currentCharIndex < text.length) {
          setCurrentText((prev) => prev + text[currentCharIndex]);
        } else if (
          currentItem.link &&
          currentCharIndex < text.length + linkText.length
        ) {
          if (!isTypingLink) {
            setIsTypingLink(true);
            setCurrentCharIndex(text.length);
          }
          setCurrentLinkText(
            (prev) => prev + linkText[currentCharIndex - text.length]
          );
        }
        setCurrentCharIndex((prev) => prev + 1);
      };

      if (typewriterFunction && typewriterFunction !== "autoload") {
        typewriterFunction(
          typeNextChar,
          currentItem,
          currentCharIndex,
          text.length + linkText.length
        );
      } else {
        if (currentCharIndex < text.length + linkText.length) {
          const timer = setTimeout(typeNextChar, speed);
          return () => clearTimeout(timer);
        }
      }

      if (currentCharIndex >= text.length + linkText.length) {
        const timer = setTimeout(() => {
          setVisibleItems((prev) => [
            ...prev,
            { ...currentItem, text: currentText, linkText: currentLinkText },
          ]);
          setCurrentItemIndex((prev) => prev + 1);
          setCurrentCharIndex(0);
          setCurrentText("");
          setCurrentLinkText("");
          setIsTypingLink(false);
        }, 50);

        return () => clearTimeout(timer);
      }
    }
  }, [
    items,
    currentItemIndex,
    currentCharIndex,
    currentText,
    currentLinkText,
    isTypingLink,
    speed,
    typewriterFunction,
  ]);

  const renderItem = (item) => {
    if (item.link) {
      return (
        <>
          {item.text}
          <a href={item.link.url} target="_blank" rel="noopener noreferrer">
            {item.linkText}
          </a>
        </>
      );
    }
    return item.text;
  };

  return (
    <ul className="content-list">
      {visibleItems.map((item) => (
        <li key={item.id} className={item.indent ? "indent" : ""}>
          {renderItem(item)}
        </li>
      ))}
      {typewriterFunction !== "autoload" && currentItemIndex < items.length && (
        <li
          className={items[currentItemIndex].indent ? "indent" : ""}
          key={items[currentItemIndex].id}
        >
          {currentText}
          {isTypingLink && (
            <a
              href={items[currentItemIndex].link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {currentLinkText}
            </a>
          )}
        </li>
      )}
    </ul>
  );
}

export default ContentList;
