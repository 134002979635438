import React, { useState, useEffect } from "react";
import ContentList from "../../components/ContentList";
import Layout from "../../components/Layout";

function Contact({ children }) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const contentList = [
      {
        id: "professional",
        text: "Professional",
        isHeader: true,
      },
      {
        id: "email",
        text: "Email: ",
        link: {
          text: "varadan.vk@gmail.com",
          url: "mailto:varadan.vk@gmail.com",
        },
        indent: true,
      },
      {
        id: "linkedin",
        text: "LinkedIn: ",
        link: {
          text: "varadankalkunte",
          url: "https://linkedin.com/in/varadankalkunte",
        },
        indent: true,
      },

      {
        id: "github",
        text: "GitHub: ",
        link: { text: "varadanvk", url: "https://github.com/varadanvk" },
        indent: true,
      },
      {
        id: "content",
        text: "Content",
        isHeader: true,
      },
      {
        id: "blog",
        text: "Blog: ",
        link: { text: "Personal Blog", url: "/blog" },
        indent: true,
      },
      {
        id: "medium",
        text: "Medium: ",
        link: { text: "varadan", url: "https://varadan.medium.com" },
        indent: true,
      },
      {
        id: "social",
        text: "Social",
        isHeader: true,
      },
      {
        id: "instagram",
        text: "Instagram: ",
        link: {
          text: "varadankalkunte",
          url: "https://www.instagram.com/varadankalkunte/",
        },
        indent: true,
      },
      {
        id: "twitter",
        text: "Twitter: ",
        link: { text: "varadankalkunte", url: "https://x.com/varadankalkunte" },
        indent: true,
      },
      // {
      //   id: "contact",
      //   text: "Contact",
      //   isHeader: true,
      // },
    ];
    setContent(contentList);
  }, []);

  return (
    <Layout>
      <ContentList items={content} typewriterFunction="autoload" />
    </Layout>
  );
}

export default Contact;
