import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import styled from "styled-components";

//TODO: Make this a database call (can run locally)
const articles = {
  mediocrity: {
    title: "The Fear of Mediocrity",
    date: "October 11, 2024",
    coverImage: "https://www.notion.so/images/page-cover/woodcuts_2.jpg",
    content: `
        <p><em>So I've been thinking about writing this essay for a <strong>long</strong> time, but I've always felt like it wasn't a priority. It mostly comes up whenever I fail at something and want to decide whether its all worth it or not<sup>  1</sup>. I have an assumption that I'll keep adding on to it or at least revisiting it over the years. This essay is technically posted on the website but its hidden – there are probably only going to be a handful of people to ever read my drafts<sup> 2</sup>. In all honesty, this should be broken up into a series of essays that have targeted focal points, but I just want to get my thoughts on to a doc right now.</em></p>
    
        <p>I've had this conversation a few times with people: <em>What do you define as success?</em> Most people give me the answer by telling me what their dream career is: Some people want to be the CEO of a big company. Others want to be world famous athletes or actors. These I can respect. But sometimes people will give me a bullshit answer like "I just want to be happy." That makes no sense to me. What do you define as happy?</p>
    
        <p>Happiness can be a lot of things. We feel happy because our brains receive dopamine. This chemical can come from internal generation like being with friends and having fun, or it can come from snorting cocaine. In either way, you're "happy". So, the ultimate goal of someone who "just wants to be happy" could be anywhere between spending time with their family often to simply shooting needles 24/7. There's no real distinction.</p>
    
        <p>What I'm trying to say here is that I'm not trying to ask people what they want to do to release dopamine, I want to know how they plan to feel fulfilled<sup> 3</sup>. Explaining this usually improves answer quality, but I still haven't really found a ground truth for what people want to achieve. This essay or journal entry is my way of trying to figure out what I need to feel fulfilled.</p>

        <p>So what is it that motivates me to do what I do? Well, I have a fear of being average. For all my life, I’ve been ridiculously competitive about absolutely everything. In short <sup> 4</sup>, I want to be in the top percentile of everything. But why? Is it for recognition? Or for the wealth and power associated with it?</p>

        <p>Honestly, I have no idea. I just know that I’m incredibly competitive and that for some odd reason I need to be in a rat race winning against other people. I wouldn’t feel a sense of purpose without it. </p>

        <p>Now as I get older, the stage of competition gets larger and has higher stakes. Being the fastest runner in my apartment was one thing; being the only student at my school to get into Stanford would be another. As I get even older, it’s only going to get harder to stay in that top percentile. </p>

        <p>This is where the fear part comes in – am I good enough to stand out? I’m aware that I have many of the tools – a high IQ, upper middle class upbringing, strong connections – but is that enough to escape mediocrity <sup> 5</sup>?</p>

        <p>Another question to ask here is <em>when do you decide that you’ve achieved enough? </em> Is it ok to simply come back to square 1, or do you want to really push the boundaries and make a lasting impact on the world? I’ve been genuinely surprised by the number of people I’ve met that have already given up on making that 1000x impact. And it goes vice versa too – somehow dreaming big is frowned upon. <em> You have to be realistic.</em> They tell you that the sky’s the limit, but then they act surprised when you start building the rocketship. </p>

        <p>There is some truth to curbing your expectations, of course. Simple probability tells us that the chance of becoming a billionaire is extraordinarily low. You get even more pessimistic if you read Gladwell – apparently there are some milestones that I should have hit when I was 4 that more or less decided my future already. </p>

        <p>But in the end, I’m human. I was made to dream big and to achieve bigger. No matter what anyone says, I’m going to stay daydreaming and believing in a future where I can escape mediocrity, one where I can really build a legacy. Because if you don’t believe in yourself, who else is going to?</p>
    
        <div class="note">
            <p><sup> 1</sup> Interestingly enough, I'm writing the first draft of this on a night where nothing has gone horribly wrong.</p>
            <p><sup> 2</sup> Hi Arnav and Amishi</p>
            <p><sup> 3</sup> This word doesn't properly describe it. I'll try to go over it through the essay.</p>
            <p><sup> 4</sup> I wrote a whole backstory about this but deleted it.</p>
            <p><sup> 5</sup> One thing to note is that by “mediocrity”, I mean going beyond what I’m already privileged to have right now. 
        </div>
    `,
  },
  gatekeep: {
    title: "The Problem with Education",
    date: "October 13, 2024",
    coverImage:
      "https://www.notion.so/images/page-cover/met_canaletto_1720.jpg",
    content: `
    <p>
  A warm, sunny day. The air conditioner cools us down, but not enough to keep
  us awake. I’m staring at the whiteboard, watching my teacher explain something
  that I already learned last year. It’s almost too easy.
</p>

<p>
  I let my mind wander for a second, thinking about what I’m going to do once
  class ends. Maybe I’ll get food with my friends. I look back at the whiteboard
</p>

<p>
  <em> Wait, I’ve never seen that equation before. </em>
</p>

<p>
  Tuning back in, I realize that there’s a new concept being explained, and
  somehow I missed an hour’s worth of context in 2 minutes. I grab my pencil to
  scribble down what’s on the slide, but it’s already gone – we’ve moved on.
  Suddenly I’ve gone from two steps ahead to a mile behind.
</p>

<p>Welcome to a day in my physics class.</p>

<p>
  Well, it’s a day in any class really. I’m either way ahead or incredibly
  behind – never just right. Looking around, it might seem like everyone else is
  on track, but a quick question will reveal that my neighbors are as lost as I
  am.
</p>

<p>
  I don’t learn the same way as everyone else – and guess what? No one does. In
  a class of 30, I’d guess there are around 4 kids that are actually learning at
  the same pace as the lesson.
</p>

<p>
  Our education system was set up to try and teach as many people at once. We
  cater to the median in the hopes that *mostly* everyone understands the
  concept. Sure, the kids on the extremes of the curve lose interest (too fast
  or too slow – same result), but our average score stays high right?
</p>

<p>
  Wrong. If we want to unlock the human mind, we must first learn to
  maximize learning potential. To do that, we need a personalized experience
  tailored to the student, not the teacher. AI-augmented education is the
  future. That’s why I’m building a personalized education platform <sup> 1</sup>
</p>   

<div class="note">
<p><sup> 1</sup> Check out <a href = "https://gatekeep.ai">Gatekeep Labs</p>
</div>
    `,
  },

  404: {
    title: "Article Not Found",
    coverImage:
      "https://www.notion.so/images/page-cover/met_winslow_homer_maine_coast.jpg",
    content: `
        <p>This article doesn't exist, why not go back to <a href="/blog">my blog</a>?</p>
    `,
  },
};

const StyledArticle = styled.div`
  .terminal-content {
    background-color: var(--bg-color);
    color: var(--text-color);
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
  }

  .article-title {
    color: var(--heading-color);
  }
  a {
    font-color: white;
    color: white;
  }
  .article-date {
    color: var(--secondary-text-color);
  }

  .article-body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    p {
      margin-bottom: 1rem;
    }

    .note {
      font-size: 0.9em;
      color: var(--secondary-text-color);
      margin-top: 30px;
      padding-bottom: 30px; // Added padding at the bottom of the note section
      font-family: Fira Code;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    sup {
      vertical-align: super;
      font-size: smaller;
      font-family: Fira Code;
    }
  }

  .article-cover-image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    object-fit: cover;
  }
`;

function Article() {
  const { id } = useParams();
  const article = articles[id];

  if (!article) {
    window.location.href = "/blog/404";
    return (
      <Layout>
        <StyledArticle className="terminal">
          <div className="terminal-content article-content">
            <h1 className="article-title">{article.title}</h1>
            <p className="article-date">{article.date}</p>
            <img
              src={article.coverImage}
              alt={article.title}
              className="article-cover-image"
            />
            <div
              className="article-body"
              dangerouslySetInnerHTML={{ __html: cleanedContent }}
            />
          </div>
        </StyledArticle>
      </Layout>
    );
  }

  // Remove the <style> tag and DOCTYPE from the content
  const cleanedContent = article.content
    .replace(/<style>[\s\S]*?<\/style>/gi, "")
    .replace(/<!DOCTYPE[^>]*>/i, "")
    .replace(/<html[^>]*>/i, "")
    .replace(/<\/html>/i, "")
    .replace(/<head>[\s\S]*?<\/head>/i, "")
    .replace(/<body[^>]*>/i, "")
    .replace(/<\/body>/i, "");

  return (
    <Layout>
      <StyledArticle className="terminal">
        <div className="terminal-content article-content">
          <h1 className="article-title">{article.title}</h1>
          <p className="article-date">{article.date}</p>
          <img
            src={article.coverImage}
            alt={article.title}
            className="article-cover-image"
          />
          <div
            className="article-body"
            dangerouslySetInnerHTML={{ __html: cleanedContent }}
          />
        </div>
      </StyledArticle>
    </Layout>
  );
}

export default Article;
