import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import ContentList from "../../components/ContentList";

const projects = [
  {
    id: "mediocrity",
    title: "The Fear of Mediocrity",
    excerpt: "How do we decide what being successful means?",
    date: "October 11, 2024",
  },
  // ... other blog posts ...
];

function Projects() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const contentList = [
      {
        id: "coming",
        text: "Coming soon...",
      },
      // {
      //   id: "llm",
      //   text: "llm-cli",
      // },
      // {
      //   id: "llm-desc",
      //   text: "Chat with SOTA LLM's within your terminal",
      //   indent: true,
      // },
      // {
      //   id: "llm-desc",
      //   text: "Python, Rust",
      //   indent: true,
      // },
      // {
      //   id: "llm-desc",
      //   text: "",
      //   link: { text: "Github", url: "github.com/varadanvk/llm-cli" },
      //   indent: true,
      // },
    ];
    setContent(contentList);
  }, []);

  return (
    <Layout>
      <ContentList items={content} />
      {/* <div className="terminal">
        <div className="terminal-content blog-content">
          <h1 className="blog-title">Blog</h1>
          <div className="blog-grid">
            {blogPosts.map((post) => (
              <article key={post.id} className="blog-post">
                <div className="blog-post-content">
                  <h2 className="blog-post-title">{post.title}</h2>
                  <p className="blog-post-date">{post.date}</p>
                  <p className="blog-post-excerpt">{post.excerpt}</p>
                  <Link to={`/blog/${post.id}`} className="blog-post-link">
                    Read
                  </Link>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div> */}
    </Layout>
  );
}

export default Projects;
