import React, { useState, useEffect } from "react";
function Header() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    // Check for saved theme preference or use system preference
    const savedTheme = localStorage.getItem("theme");
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (savedTheme === "light" || (!savedTheme && !prefersDark)) {
      setIsDarkMode(false);
      document.body.classList.add("light-mode");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (isDarkMode) {
      document.body.classList.add("light-mode");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.remove("light-mode");
      localStorage.setItem("theme", "dark");
    }
  };

  return (
    <div className="terminal-header">
      <div className="terminal-title">
        <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
          Varadan Kalkunte
        </a>
      </div>
      <div className="terminal-controls">
        <a href="/blog" className="header-link">
          Blog
        </a>
        <a href="/projects" className="header-link">
          Projects
        </a>
        <a href="/contact" className="header-link">
          Contact
        </a>
        <a onClick={toggleDarkMode} className="header-link">
          {isDarkMode ? "Night" : "Day"}
        </a>
      </div>
    </div>
  );
}

export default Header;
