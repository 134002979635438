import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import Article from "./pages/Blog/Articles/Article";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Projects/Project/Project";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/404/404";
import "./App.css";
import BananaPrank from "./components/Banana";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<Article />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="hawk" element={<BananaPrank />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
